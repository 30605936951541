<template>
  <div class="container tech" data-view>
    <el-row type="flex" justify="space-between" align="middle" class="tech-row">
      <el-col :xs="24" :sm="12" class="tech-text">
        <div class="tech-img d-hide">
          <img
            src="@/assets/img/404.svg"
            alt=""
            data-copyright-source="https://storyset.com/illustration/404-error/cuate"
            data-copyright-name="Storyset"
            data-copyright-owner="Storyset"
            data-copyright-profile="https://storyset.com/"
          />
        </div>
        <h1>404&nbsp;ошибка!</h1>
        <p class="p-3 bold">Мы&nbsp;не&nbsp;нашли этой страницы</p>
        <p class="p-4">
          К&nbsp;сожалению, запрашиваемой вами страницы на&nbsp;нашем сайте
          не&nbsp;существует. Возможно, это случилось по&nbsp;одной
          из&nbsp;следующих причин:
        </p>
        <ul class="list">
          <li>Вы&nbsp;ошиблись при наборе адреса страницы (URL)</li>
          <li>Перешли по&nbsp;неработающей ссылке</li>
          <li>Этой страницы никогда не&nbsp;было или она удалена</li>
        </ul>
        <div class="t56">
          <p class="p-4">
            Приносим извинения за&nbsp;предоставленные неудобства
            и&nbsp;предлагаем:
          </p>
          <ul class="list">
            <li>
              Вернуться
              <button class="btn-link" @click="goBack">назад</button>
            </li>
            <li>
              Перейти на&nbsp;<router-link
                :to="{ name: 'Home' }"
                class="btn-link"
                >главную страницу</router-link
              >
              сайта
            </li>
            <li>
              Обратиться с&nbsp;интересующим вас вопросом в&nbsp;поддержку
              сервиса по&nbsp;электронной почте.
            </li>
          </ul>
        </div>
      </el-col>
      <el-col :xs="24" :sm="10" class="tech-img m-hide">
        <img
          src="@/assets/img/404.svg"
          alt=""
          data-copyright-source="https://storyset.com/illustration/404-error/cuate"
          data-copyright-name="Storyset"
          data-copyright-owner="Storyset"
          data-copyright-profile="https://storyset.com/"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
const meta = {
  title: "Страница не найдена",
};

export default {
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
  },
  metaInfo: {
    title: meta.title,
    meta: [
      {
        name: "og:title",
        content: meta.title,
      },
      {
        name: "robots",
        content: "none",
      },
    ],
  },
};
</script>
